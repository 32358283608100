import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo"
import CTA from "../components/cta";

import BlogFeed from "../components/blog-feed";

import patternHex from "../images/backrounds/pattern-hex-white.jpg";
import NeedAQuote from "../components/need-a-quote";
import Quote from "../components/quote";

const Index = ({location}) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Custom Zendesk Guide Themes"
      description="Customize your Zendesk Help Center without the hassle of building it from scratch. Our Zendesk themes are easier to modify with greater customization opportunities so you can get the look you really want."
    />
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${patternHex})`,
        }}
      >
        <div className="hero-title bg-dark-transparent">
          <div className="hero-image">
            <StaticImage
              src="../images/clients/zendesk.png"
              alt="Zendesk Implementation Premier Partner"
              layout="constrained"
              style={{ maxWidth: "200px"}}
            />
          </div>
          <h1 className="text-white">Custom Tailored Zendesk Guide Themes</h1>
          <p className="text-white">
            Immediately improve your branding and customer experience—without
            blowing your budget. Our pre-built themes make customizations to
            your Zendesk Help Center easy.
          </p>
          <CTA
            href="/contact-us/"
            className="btn btn-primary"
            text="GET A CUSTOM ZENDESK THEME"
          />
        </div>
      </div>
    </section>
    <section className="bg-dark">
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-4">
            <StaticImage
              src="../../images/partners/zendesk-implementation-premier-partner-badge.png"
              alt="Zendesk Implementation Premier Partner"
            />
          </div>
          <div className="col-lg-8 d-flex align-items-center">
            <h2 className="text-white">
              A Master Zendesk Development and Design Shop
            </h2>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>Upgrade Your Zendesk Theme</h2>
            <p>
              You know design, branding, and usability matters, but the time to
              implement changes is scarce. 729 is a Master Zendesk Partner with
              a proven record of finding customer solutions for every Zendesk
              problem. Beyond our pre-built themes, we work within the whole
              Zendesk Ecosystem and can assist with customizations to Zendesk
              Support, Chat, integration of third-party tools, and migrations.
            </p>

            <p>
              When you work with 729, you get an experienced partner helping you
              leverage all that Zendesk has to offer.
            </p>
            <h2>Our Zendesk Help Center Themes</h2>
            <h3>
              Choose from one of our Zendesk templates, or work with us to create your own
            </h3>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-light">
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-6 mt-n5">
            <StaticImage
              className="mt-n6 mb-6"
              src="../images/themes/tidy.png"
              alt="Tidy Cards Zendesk Theme"
            />
          </div>
          <div className="col-lg-6 pb-6">
            <StaticImage
              className="w-50 mb-4"
              src="../images/themes/tidy-logo.png"
              alt="Tidy Cards Zendesk Theme"
            />
            <p>
              <b>Tidy Cards:</b> This modern, clean theme streamlines
              information to give customers easy access to your most important
              articles. No more jumping through hoops to get what they need.
            </p>

            <CTA
              href="https://tidy729.zendesk.com/"
              className="btn btn-primary"
              text="VIEW THE TIDY THEME DEMO"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-6 mt-n5">
            <StaticImage
              className="mt-n6 mb-6"
              src="../images/themes/snap.png"
              alt="Snap Zendesk Theme"
            />
          </div>
          <div className="col-lg-6 pb-6">
            <StaticImage
              className="w-50 mb-4"
              src="../images/themes/snap-logo.png"
              alt="Snap Zendesk Theme"
            />
            <p>
              <b>Snap:</b> A step above standard, make navigating your Help
              Center a “snap” with this theme. Use Snap to give customers easy
              access to your FAQs, How-Tos, and recommended articles.
            </p>

            <CTA
              href="https://snap729.zendesk.com/"
              className="btn btn-primary"
              text="VIEW THE SNAP THEME DEMO"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="bg-light">
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-6 mt-n5">
            <StaticImage
              className="mt-n6 mb-6"
              src="../images/themes/sprout.png"
              alt="Sprout Zendesk Theme"
            />
          </div>
          <div className="col-lg-6 pb-6">
            <StaticImage
              className="w-50 mb-4"
              src="../images/themes/sprout-logo.png"
              alt="Sprout Zendesk Theme"
            />
            <p>
              <b>Sprout:</b> If you want more than just another version of
              Copenhagen, Sprout might be just what you’re looking for. Sprout
              is easy to navigate and links directly back to your branded
              website.
            </p>

            <CTA
              href="https://sprout729.zendesk.com/"
              className="btn btn-primary"
              text="VIEW THE SPROUT THEME DEMO"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-6 mt-n5">
            <StaticImage
              className="mt-n6"
              src="../images/themes/eminence.png"
              alt="Eminence Zendesk Theme"
            />
          </div>
          <div className="col-lg-6">
            <StaticImage
              className="w-50 mb-4"
              src="../images/themes/eminence-logo.png"
              alt="Eminence Zendesk Theme"
            />
            <p>
              <b>Eminence:</b> With an inviting contrast of color focused on
              making your brand memorable and its large, purposeful iconography,
              Eminence is an exceptional Zendesk Guide Theme for your Zendesk
              Help Center.
            </p>

            <CTA
              href="https://eminence729.zendesk.com/hc/en-us"
              className="btn btn-primary"
              text="VIEW THE EMINENCE THEME DEMO"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pb-6">
        <div className="row">
          <div className="col">
            <h2>Customizing Your Zendesk Theme</h2>
            <h3>Take Your Zendesk Guide Theme to the Next Level</h3>
            <p>
              Once we understand what your business is trying to achieve with
              Zendesk, and what features are critical to making sure you
              succeed, we can recommend one of our pre-built themes and ideas
              for any additional customizations you might like.
            </p>

            <p>
              We’ll give you mock-ups of what your new Zendesk Help Center will
              look like, and when approved, it’s time to implement the changes.
              By adding your logo, font, and brand colors we give you something
              different from your competitors, at a fraction of the cost of a
              fully custom theme.
            </p>

            <h2>Unique Zendesk Features</h2>
            <h3>
              Your Zendesk Help Center, Made Easier for You and Your Customers
            </h3>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-5">
          <div className="col mb-4">
            <div className="card custom-cards card-border h-100">
              <div className="card-body">
                <svg
                  className="text-primary"
                  height={100}
                  viewBox="0 0 144 144"
                  width={100}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M41.29,87.93H21.85a7.47,7.47,0,0,1-7.44-7.45V36.66a7.47,7.47,0,0,1,7.44-7.45H49.58"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    d="M105.71,29.21h16.44a7.47,7.47,0,0,1,7.44,7.45V80.48a7.47,7.47,0,0,1-7.44,7.45h-10"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    d="M38.66,81.37H36a5.6,5.6,0,0,1-5.58-5.58V41.34A5.59,5.59,0,0,1,36,35.77h13.8"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <path
                    d="M105.35,35.77a5.58,5.58,0,0,1,5.57,5.57V75.79a5.59,5.59,0,0,1-5.57,5.58H63.5"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="22.55"
                    x2="22.55"
                    y1="52.44"
                    y2="63.55"
                  />
                  <circle
                    cx="119.89"
                    cy="57.99"
                    fill="none"
                    r="4.41"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <rect
                    fill="none"
                    height="25.23"
                    rx="5"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    width="10.01"
                    x="54.49"
                    y="22.32"
                  />
                  <rect
                    fill="none"
                    height="25.23"
                    rx="5"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    width="10.01"
                    x="66.73"
                    y="22.32"
                  />
                  <rect
                    fill="none"
                    height="25.23"
                    rx="5"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    width="10.01"
                    x="78.97"
                    y="22.32"
                  />
                  <rect
                    fill="none"
                    height="25.23"
                    rx="5"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    width="10.01"
                    x="91.21"
                    y="22.32"
                  />
                  <path
                    d="M60.12,110.48,43.35,80.64s-4-5.91-4.17-10.1,6.69-4.4,11.54,1.84S63.15,87.79,63.15,87.79H106.4s5.26,18.52-7.7,28.4S68.58,121.68,60.12,110.48Z"
                    fill="none"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                </svg>
                <b className="d-block mb-3">Mobile-Optimized</b>
                Our responsive themes for the Zendesk Help Center are designed to
                work great on any device: desktop, tablet, or smartphone.
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards card-border h-100">
              <div className="card-body">
                <svg
                  className="text-primary"
                  height={100}
                  viewBox="0 0 144 144"
                  width={100}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.25,51.57,7.57,59.12,24.39,78"
                    fill="none"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <path
                    d="M25.51,43.36,14.75,46.67l10.76,35"
                    fill="none"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <rect
                    data-name="Rectangle 159"
                    fill="none"
                    height="53.8"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    width="21.24"
                    x="25.53"
                    y="39.08"
                  />
                  <circle
                    cx="36.27"
                    cy="84.23"
                    fill="none"
                    r="2.69"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="46.77"
                    x2="25.53"
                    y1="75.19"
                    y2="75.19"
                  />
                  <polyline
                    fill="none"
                    points="39.69 30.59 39.69 22.8 121.11 22.8 121.11 45.45"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <polyline
                    fill="none"
                    points="121.11 105.63 121.11 121.2 39.69 121.2 39.69 102.09"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="88.54"
                    x2="99.16"
                    y1="44.74"
                    y2="44.74"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="88.54"
                    x2="99.16"
                    y1="61.73"
                    y2="61.73"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="63.05"
                    x2="99.16"
                    y1="78.73"
                    y2="78.73"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="63.05"
                    x2="99.16"
                    y1="95.72"
                    y2="95.72"
                  />
                  <rect
                    fill="none"
                    height="18.41"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    width="11.33"
                    x="63.05"
                    y="44.74"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="121.11"
                    x2="121.11"
                    y1="53.59"
                    y2="71.29"
                  />
                  <circle
                    cx="121.41"
                    cy="74.79"
                    fill="none"
                    r="3.54"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <polyline
                    fill="none"
                    points="130.42 89.81 136.43 75.79 121.41 53.76 106.39 75.79 112.4 89.81"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <rect
                    fill="none"
                    height="5.66"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    width="28.32"
                    x="106.95"
                    y="90.05"
                  />
                </svg>
                <b className="d-block mb-3">ON BRAND</b>
                <p>
                  All of our themes make it easy for you to create a knowledge
                  base that meets your unique branding needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards card-border h-100">
              <div className="card-body">
                <svg
                  className="text-primary"
                  height={100}
                  viewBox="0 0 144 144"
                  width={100}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M13,34.33l28.4-15.07L69.82,34.33,41.41,49.4Z"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <rect
                      fill="none"
                      height="26.37"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      width="48.07"
                      x="82.92"
                      y="58.82"
                    />
                    <path
                      d="M30.49,98.37H52.34c8.44,0,15.29,5.9,15.29,13.18h0c0,7.28-6.85,13.19-15.29,13.19H30.49c-8.45,0-15.3-5.91-15.3-13.19h0C15.19,104.27,22,98.37,30.49,98.37Z"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="41.41"
                      x2="41.41"
                      y1="49.4"
                      y2="98.37"
                    />
                    <path
                      d="M69.82,34.33H107V47.52"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M67.63,111.55H107V96.48"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                  </g>
                </svg>
                <b className="d-block mb-3">Greater Flexibility</b>
                <p>
                  We’ve extended the visual editor of all our Zendesk Guide themes
                  to give you access to more great features – no coding knowledge
                  required.
                </p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards card-border h-100">
              <div className="card-body">
                <svg
                  className="text-primary"
                  height={100}
                  viewBox="0 0 144 144"
                  width={100}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    d="M35.9,75.6c5.8-5.6,12.7-3.4,18.5,2.2l11.8,8.8V36.8c0-10.7,13.4-10.7,13.4,0v27
                            c0-4,3.9-7.2,8-7.2s8,3.2,8,7.2v5.6c0-4,1.6-7.2,5.8-7.2c4.1-0.1,7.5,3.1,7.6,7.2v6.7c0-4,1.8-7.2,6-7.2s6.5,3.2,6.5,7.2
                            l-4.9,25c0,0-6,26.1-27.4,26.1c-16.8,0-26.6-14.2-26.6-14.2L35.9,75.6z"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="79.6"
                    y1="60.9"
                    x2="79.6"
                    y2="71.6"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="95.7"
                    y1="68.9"
                    x2="95.7"
                    y2="74.3"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="109"
                    y1="74.3"
                    x2="109"
                    y2="79.6"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    d="M57.7,48.1c-6.7-8-5.7-20,2.3-26.8s20-5.7,26.8,2.3c6.3,7.5,5.8,18.7-1.1,25.6"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="26.2"
                    y1="34.2"
                    x2="44.9"
                    y2="34.2"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="31"
                    y1="41.7"
                    x2="22.5"
                    y2="34.2"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="31"
                    y1="26.6"
                    x2="22.5"
                    y2="34.2"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="118"
                    y1="34.2"
                    x2="99.2"
                    y2="34.2"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="113.2"
                    y1="26.6"
                    x2="121.6"
                    y2="34.2"
                  />

                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="113.2"
                    y1="41.7"
                    x2="121.6"
                    y2="34.2"
                  />
                </svg>

                <b className="d-block mb-3">Customer Experience Ready</b>
                <p>
                  All of our Zendesk Guide themes were developed with the customer
                  experience in mind by drawing on the expertise of our User
                  Experience consultants.
                </p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards card-border h-100">
              <div className="card-body">
                <svg
                  className="text-primary"
                  height={100}
                  viewBox="0 0 144 144"
                  width={100}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M95.73,84.46v46.6L72.56,117.82,49.4,131.06V84.46"
                    fill="none"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <circle
                    cx="72"
                    cy="52.23"
                    fill="none"
                    r="39.28"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <polygon
                    fill="none"
                    points="71.37 31.56 75.71 45.41 90.16 45.41 78.67 54.04 83.19 67.93 71.37 59.34 59.55 67.93 64.07 54.04 52.58 45.41 67.03 45.41 71.37 31.56"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                </svg>
                <b className="d-block mb-3">
                  CUSTOMER SATISFACTION IS OUR PRIORITY
                </b>
                <p>
                  Our goal is to help you create a Zendesk Help Center exactly as
                  you imagined it, and ready to meet all your customer’s needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      <div className="row pt-5">
        <div className="col text-center">
          <CTA
            href="/contact-us/"
            className="btn btn-primary"
            text="GET A CUSTOM ZENDESK THEME"
          />
        </div>
      </div>
      </div>
    </section>
    <section className="bg-light">
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>Our Approach</h2>
            <h3>Everything you need out of your zendesk help center, none of the hassle.</h3>
            <p>
              We always begin with a deep dive into your needs to make sure we understand the functionality you require. From there, you can choose from any of our prebuilt Zendesk Help Center themes. We will install the template of your choosing, apply all your branding, and ensure that everything is working smoothly.
            </p>
            <p>
              Beyond that, 729 is a “one-stop-shop,” able to meet all your Zendesk needs. Whether it’s design and development, efficiency, or even training your customer service agents, we can help.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-dark">
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2 className="text-white">Here is what clients say about us</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Quote
              dark
              name="Logan McCabe"
              company="Universal Music Group"
              quote="729 has gone above and beyond in each initiative we've undertook together, from providing deeply-considered insight to bringing our team's initiatives to life."
            />
          </div>
          <div className="col">
            <Quote
              dark
              name="Kate Benay"
              company="Director of Marketing, Sundance Institute"
              quote="729 took the time to understand more than just our UI/UX concerns - they explored our business goals as well. This has allowed us to make decisions that consider the larger, long term vision, in addition to solving the immediate needs we brought to the table."
            />
          </div>
        </div>
      </div>
    </section>
    <BlogFeed containerClassname="py-6" />
  </Layout>
);

export default Index;
